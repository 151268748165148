import {Form} from '@remix-run/react'
import {Button} from '@translated/babele-react'
import {TaxiFatalError01, TaxiPageNotFound01} from './Images'

export const Error401State = (
  <div className="flex flex-col items-center m-auto w-full max-w-lg gap-10 p-6">
    <TaxiPageNotFound01 />
    <div className="flex flex-col gap-6 items-center">
      <div className="flex flex-col gap-4 items-center">
        <div className="flex flex-col gap-2 items-center">
          <div className="bg-notice-100 text-notice-1000 font-medium rounded-lg px-3 py-0.5 text-sm">
            Error 401
          </div>
          <h1 className="text-display-md font-bold text-center">
            Unauthorized Access
          </h1>
        </div>
        <div className="text-center">
          It seems you're trying to access a page you're not authorized to view.
          Please make sure you're logged in and have the necessary permissions
          to access this page.
        </div>
      </div>
      <Form method="POST" action="/logout">
        <Button variant={{tone: 'critical'}}>Logout</Button>
      </Form>
    </div>
  </div>
)

export const Error404State = ({
  actionsSlot,
}: {
  actionsSlot?: React.ReactNode
}) => {
  return (
    <div className="flex flex-col items-center m-auto w-full max-w-lg gap-10 p-6">
      <TaxiPageNotFound01 />
      <div className="flex flex-col gap-6 items-center">
        <div className="flex flex-col gap-4 items-center">
          <div className="flex flex-col gap-2 items-center">
            <div className="bg-notice-100 text-notice-1000 font-medium rounded-lg px-3 py-0.5 text-sm">
              Error 404
            </div>
            <h1 className="text-display-md font-bold text-center">
              Oops! Lost in the Digital Cosmos?
            </h1>
          </div>
          <div className="text-center">
            We can't seem to find the page you're searching for. It might have
            been moved, renamed, or might never have existed.
          </div>
        </div>
        {actionsSlot && <div>{actionsSlot}</div>}
      </div>
    </div>
  )
}

export const Error500State = (
  <div className="flex flex-col items-center m-auto w-full max-w-lg gap-10 p-6">
    <TaxiFatalError01 />
    <div className="flex flex-col gap-4 items-center">
      <div className="flex flex-col gap-2 items-center">
        <div className="bg-critical-100 text-critical-1000 font-medium rounded-lg px-3 py-0.5 text-sm">
          Error 500
        </div>
        <h1 className="text-display-md font-bold text-center">
          Whoops! We Hit a Snag.
        </h1>
      </div>
      <div className="text-center">
        Our server is currently experiencing a hiccup and couldn't process your
        request. We're on it, though! In the meantime, you can refresh the page
        or come back a bit later. We appreciate your patience and apologize for
        any inconvenience.
      </div>
    </div>
  </div>
)
